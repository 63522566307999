import { ContextApi } from '@pancakeswap/localization'
import {
  DropdownMenuItems,
  EarnFillIcon,
  EarnIcon,
  InfoFilledIcon,
  InfoIcon,
  MenuItemsType,
  ResourcesFilledIcon,
  ResourcesIcon,
  SwapFillIcon,
  SwapIcon,
} from '@pancakeswap/uikit'
import { SUPPORT_CAKE_STAKING, SUPPORT_FARMS } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href:
        chainId === 56 ? '/swap?inputCurrency=BNB&outputCurrency=0x55d398326f99059fF775485246999027B3197955' : '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href:
            chainId === 56
              ? '/swap?inputCurrency=BNB&outputCurrency=0x55d398326f99059fF775485246999027B3197955'
              : '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Buy'),
    //   href: '/buy-crypto',
    //   icon: ShoppingBasketIcon,
    //   fillIcon: ShoppingBasketFilledIcon,
    //   items: [
    //     {
    //       label: t('Buy Crypto'),
    //       href: '/buy-crypto',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Earn'),
      href: '/#',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',
      supportChainIds: SUPPORT_FARMS,
      items: [
        {
          label: t('CHEASY Staking (coming soon)'),
          href: '/cake-staking',
          supportChainIds: SUPPORT_CAKE_STAKING,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Info'),
      href: '/info',
      icon: InfoIcon,
      fillIcon: InfoFilledIcon,
      image: '/images/decorations/pe2.png',
      showItemsOnMobile: false,
    },
    // {
    //   label: t('Game'),
    //   icon: PancakeProtectorIcon,
    //   hideSubNav: true,
    //   href: 'https://pancakeswap.games/',
    //   items: [
    //     {
    //       label: t('Gaming Marketplace'),
    //       href: 'https://pancakeswap.games/',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('Prediction (BETA)'),
    //       href: '/prediction',
    //       image: '/images/decorations/prediction.png',
    //       supportChainIds: PREDICTION_SUPPORTED_CHAINS,
    //     },
    //     {
    //       label: t('Lottery'),
    //       href: '/lottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //     {
    //       label: t('Pottery (BETA)'),
    //       href: '/pottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('NFT'),
    //   href: `${nftsBaseUrl}`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   items: [
    //     {
    //       label: t('Overview'),
    //       href: `${nftsBaseUrl}`,
    //     },
    //     {
    //       label: t('Collections'),
    //       href: `${nftsBaseUrl}/collections`,
    //     },
    //     {
    //       label: t('Activity'),
    //       href: `${nftsBaseUrl}/activity`,
    //     },
    //   ],
    // },
    // {
    //   label: t('v4'),
    //   href: '/v4',
    //   showOnMobile: false,
    //   items: [],
    // },
    {
      label: 'Docs',
      href: 'https://docs.cheasy.finance/',
      icon: ResourcesIcon,
      fillIcon: ResourcesFilledIcon,
      showItemsOnMobile: false,
      // items: [
      //   // {
      //   //   label: t('Affiliate Program'),
      //   //   href: '/affiliates-program',
      //   // },
      //   // {
      //   //   label: t('Voting'),
      //   //   href: '/voting',
      //   //   supportChainIds: SUPPORT_ONLY_BSC,
      //   //   image: '/images/voting/voting-bunny.png',
      //   // },
      //   // {
      //   //   label: t('Leaderboard'),
      //   //   href: '/teams',
      //   //   supportChainIds: SUPPORT_ONLY_BSC,
      //   //   image: '/images/decorations/leaderboard.png',
      //   // },
      //   // {
      //   //   label: t('Blog'),
      //   //   href: 'https://docs.cheasy.finance/whitepaper/cheasyswap-introduction',
      //   //   type: DropdownMenuItemType.EXTERNAL_LINK,
      //   // },
      //   {
      //     label: t('Docs'),
      //     href: 'https://docs.cheasy.finance/',
      //     type: DropdownMenuItemType.EXTERNAL_LINK,
      //   },
      //   // {
      //   //   label: t('v4'),
      //   //   href: '/v4',
      //   //   isMobileOnly: true,
      //   // },
      // ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
