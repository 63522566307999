import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Ecosystem"),
    items: [
      {
        label: t("Trade"),
        href: "/swap",
      },
    ],
  },
  {
    label: t("Developers"),
    items: [
      {
        label: t("Contributing"),
        href: "https://github.com/CheasySwap",
      },
      {
        label: t("Github"),
        href: "https://github.com/CheasySwap",
      },
    ],
  },
  {
    label: t("Support"),
    items: [
      {
        label: t("Contact"),
        href: "mailto:support@cheasy.finance",
      },
      {
        label: t("Troubleshooting"),
        href: "mailto:support@cheasy.finance",
      },
      {
        label: t("Documentation"),
        href: "https://docs.cheasy.finance/",
      },
    ],
  },
  {
    label: t("About"),
    items: [
      {
        label: t("Terms Of Service"),
        href: "https://docs.cheasy.finance/docs/disclaimers",
      },
      // {
      //   label: t("Blog"),
      //   href: "https://docs.cheasy.finance/whitepaper/cheasyswap-overview",
      // },
    ],
  },
];
