import React from "react";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <>
    <img src="cheasy.png" alt="logo" width={45} height={40}/>
    <h1 style={{color: "white", fontSize: "1.5rem", marginLeft: "10px" , marginTop:"10px"}}>CheasySwap</h1>
    </>
  );
};

export default Logo;
